.grid-container-obs {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Crea cuatro columnas de igual tamaño */
    grid-gap: 10px; /* Espacio entre los elementos */
}

@media screen and (max-width: 576px) {
    /* Para pantallas pequeñas, muestra solo 1 columna */
    .grid-container-obs {
        grid-template-columns: 1fr;
    }
    .grid-item-Documento {
        grid-column: span 1
    }
}

@media screen and (min-width: 576px ) and (max-width: 767px) {
    /* Para pantallas medianas, muestra 2 columnas */
    .grid-container-obs {
        grid-template-columns: repeat(1, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 2
    }
}

@media screen and (min-width: 768px  ) and (max-width: 991px) {
    /* Para pantallas medianas, muestra 2 columnas */
    .grid-container-obs {
        grid-template-columns: repeat(2, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 3
    }
}

@media screen and (min-width: 992px  ) and (max-width: 1399px) {
    /* Para pantallas medianas, muestra 2 columnas */
    .grid-container-obs {
        grid-template-columns: repeat(3, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 5
    }
}

@media screen and (min-width: 1400px) {
    /* Para pantallas grandes y superiores, muestra 4 columnas */
    .grid-container-obs {
        grid-template-columns: repeat(3, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 5
    }
}