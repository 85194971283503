.pdf-viewer-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 100vh; */
  }
  
  .spinner-container {
    text-align: center;
  }
  