.card {
    transition: height 0.3s ease;
    overflow: hidden;
}

.card-content {
visibility: visible;
opacity: 1;
transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.card.minimized {
height: 60px;
}

.card.minimized .card-content {
visibility: hidden;
opacity: 0;
transition-delay: 0.3s, opacity 0.3s;;
}

.card-toolbar{
    flex-direction: column !important;
}

.card-seccion{    
    padding: 20px;
    opacity: 100%;
    color: #fff;
}

.card-seccion-header{
    padding-right: 20px;
    min-height: 20px !important;
    opacity: 100%;
    color: #fff;

}



.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Crea cuatro columnas de igual tamaño */
    grid-gap: 10px; /* Espacio entre los elementos */
}

.grid-container-seccion {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Crea cuatro columnas de igual tamaño */
    grid-gap: 10px; /* Espacio entre los elementos */
}


.grid-item-Documento {
    grid-column: span 4
}

@media screen and (max-width: 576px) {
    /* Para pantallas pequeñas, muestra solo 1 columna */
    .grid-container {
        grid-template-columns: 1fr;
    }
    .grid-item-Documento {
        grid-column: span 1
    }
}

@media screen and (min-width: 576px ) and (max-width: 767px) {
    /* Para pantallas medianas, muestra 2 columnas */
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 2
    }
}

@media screen and (min-width: 768px  ) and (max-width: 991px) {
    /* Para pantallas medianas, muestra 2 columnas */
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 3
    }
}

@media screen and (min-width: 992px  ) and (max-width: 1399px) {
    /* Para pantallas medianas, muestra 2 columnas */
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 5
    }
}

@media screen and (min-width: 1400px) {
    /* Para pantallas grandes y superiores, muestra 4 columnas */
    .grid-container {
        grid-template-columns: repeat(5, 1fr);
    }
    .grid-item-Documento {
        grid-column: span 5
    }
}

.form-select {
    padding-block: 0%;
    height: 35px; /* Ajusta la altura según tus necesidades */
    font-size: 14px; /* Ajusta el tamaño de fuente según tus necesidades */
}

.custom-date-input {
    /* Estilos personalizados */
    width: 100%;
    height: 33px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    /* Otros estilos deseados */
}


/* dropzone.css */
.dropzone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    background-color: #f8f8f8;
    cursor: pointer;
}

.dropzone.active {
border-color: #0088cc;
background-color: #f0f8ff;
}

.dropzone p {
font-size: 12px;
margin: 0;
padding: 10px;
}

/* Optional: Style for the file previews */
.dropzone-preview {
position: relative;
display: inline-block;
width: 100px;
height: 100px;
border: 1px solid #ccc;
border-radius: 4px;
margin: 5px;
overflow: hidden;
transition: opacity 0.3s;
}

.dropzone-preview:hover {
    opacity: 0.8; /* Ajusta el valor de opacidad según tus preferencias */
}

.dropzone-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Espacio entre los elementos */
    padding: 16px; /* Espacio interno para que los elementos no toquen los bordes */
    border: 2px solid #ccc; /* Borde del contenedor */
    border-radius: 4px; /* Bordes redondeados para que se vea más amigable */
}

.dropzone-preview img {
width: 100%;
height: 100%;
object-fit: cover;
}

.dropzone-preview2 {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    transition: opacity 0.3s;
}

.dropzone-preview2:hover {
    opacity: 0.8; /* Ajusta el valor de opacidad según tus preferencias */
}

.dropzone-preview2-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Espacio entre los elementos */
    padding: 16px; /* Espacio interno para que los elementos no toquen los bordes */
    border: 2px solid #ccc; /* Borde del contenedor */
    border-radius: 4px; /* Bordes redondeados para que se vea más amigable */
}

.file-info{
    width: 100%;
    height: 100%;
    background: #000064;
    padding: 5px;
    font-size: 10px;
    color: #ccc;
}


.file-info2{
    width: 100%;
    height: 100%;
    background: #EAEAEA;
    padding: 5px;
    font-size: 10px;
    color: #000000;
}

.remove-btn {
    position: absolute;
    top: 4px;
    left: 71px;
    background-color: rgb(240, 234, 234);
    border: none;
    padding: 5px;
    cursor: pointer;
}
.remove-btn:hover {
    background-color: rgb(179, 54, 54);
    color: white;
}


.remove-btn2 {
    position: absolute;
    top: 2px;
    right: 10px;
    background-color: rgb(240, 234, 234);
    border: none;
    padding: 5px;
    cursor: pointer;
}
.remove-btn2:hover {
    background-color: rgb(179, 54, 54);
    color: white;
}

.preview-btn {
    position: absolute;
    top: 33px;
    left: 32px;
    opacity: 80%;
    background-color: red;
    border: none;
    padding: 5px;
    cursor: pointer;
}.preview-btn:hover {
    background-color: rgb(179, 54, 54);
    opacity: 100%;
    color: white;
}


.preview-btn2 {
    position: absolute;
    top: 2px;
    right: 40px;
    opacity: 80%;
    background-color: rgb(240, 234, 234);
    border: none;
    padding: 5px;
    cursor: pointer;
}.preview-btn2:hover {
    background-color: #18C073;
    opacity: 100%;
    color: black;
}

.remove-all-btn {
background-color: rgb(241, 65, 108) !important;
color: white;
border: none;
padding: 10px 15px;
margin-top: 10px;
cursor: pointer;
}

.add-btn {
    position: absolute;
    top: 6px;
    right: 120px;
}





.button-container {
    display: flex; /* Utilizamos Flexbox para alinear los botones en línea */
    gap: 10px; /* Espaciado entre los botones */
    justify-content: center; /* Centramos los botones horizontalmente */
    margin-top: 5px; /* Margen superior */
}

/* Estilos para los botones */
.btn {
    /* Agrega aquí los estilos que desees para los botones */
}

/* Estilos para el botón "Limpiar" */
.btn-light-danger {
    /* Agrega aquí los estilos que desees para el botón "Limpiar" */
}

/* Estilos para el botón "Guardar firma" */
.btn-light-success {
    /* Agrega aquí los estilos que desees para el botón "Guardar firma" */
}

/* Estilos adicionales para hacerlo responsive en pantallas pequeñas */
@media screen and (max-width: 768px) {
    .button-container {
    flex-direction: column; /* Cambia a una columna en pantallas pequeñas */
    align-items: center; /* Centra los botones verticalmente */
        }
}

.table-tr {
    padding: 0%;
}

/* palpitar */
.jump-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.jumping {
animation: jumpAnimation 1s ease-in-out infinite;
}

@keyframes jumpAnimation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
}

.disabled {
    pointer-events: none; /* Deshabilita interacciones en el div y sus hijos */
    opacity: 0.8; /* Cambia la opacidad para indicar que está deshabilitado */
}