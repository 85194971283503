/* CSS for modal animation */
.modal {
  display: none;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modal.show {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

