.banner-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: 15px;
  }
  
  .banner-svg {
    width: 100%;
    height: auto;
    display: block;
  }
  