.elevator-with-shadow {
    width: 100%; /* Ancho del elemento */
    height: 160px; /* Altura del elemento */
    background-color: #E1E3EA; /* Color de fondo del elemento */
    border-radius: 8px; /* Radio de borde para hacerlo ligeramente redondeado */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombras (horizontal, vertical, desenfoque, color) */
    transition: box-shadow 0.3s ease-in-out; /* Transición suave de la sombra */
}

.elevator-with-shadow:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); /* Cambiar la sombra en el hover */
}

.float-component {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: none;
}

.float-component.visible {
    display: block;
}