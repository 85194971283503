.table-constrained-height {
    max-height: 50px !important; /* Puedes ajustar el valor de 'max-height' según tus necesidades */
    overflow-y: auto !important; /* Agrega una barra de desplazamiento vertical si el contenido supera la altura máxima */
}

/* dropzone.css */
.g-dropzone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    background-color: #f8f8f8;
    cursor: pointer;
}

.g-dropzone.active {
border-color: #0088cc;
background-color: #f0f8ff;
}

.g-dropzone p {
font-size: 12px;
margin: 0;
padding: 10px;
}

/* Optional: Style for the file previews */
.g-dropzone-preview {
position: relative;
display: inline-block;
width: 100px;
height: 100px;
border: 1px solid #ccc;
border-radius: 4px;
margin: 5px;
overflow: hidden;
transition: opacity 0.3s;
}

.g-dropzone-preview:hover {
    opacity: 0.8; /* Ajusta el valor de opacidad según tus preferencias */
}

.g-dropzone-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Espacio entre los elementos */
    padding: 16px; /* Espacio interno para que los elementos no toquen los bordes */
    border: 2px solid #ccc; /* Borde del contenedor */
    border-radius: 4px; /* Bordes redondeados para que se vea más amigable */
}

.g-dropzone-preview img {
width: 100%;
height: 100%;
object-fit: cover;
}

.g-dropzone-preview2 {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 45px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    transition: opacity 0.3s;
}

.g-dropzone-preview2:hover {
    opacity: 0.8; /* Ajusta el valor de opacidad según tus preferencias */
}

.g-dropzone-preview2-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Espacio entre los elementos */
    padding: 16px; /* Espacio interno para que los elementos no toquen los bordes */
    border: 2px solid #ccc; /* Borde del contenedor */
    border-radius: 4px; /* Bordes redondeados para que se vea más amigable */
}

.g-file-info{
    width: 100%;
    height: 100%;
    background: #000064;
    padding: 5px;
    font-size: 10px;
    color: #ccc;
}


.g-file-info2{
    width: 100%;
    height: 100%;
    background: #EAEAEA;
    padding: 5px;
    font-size: 10px;
    color: #000000;
}

.preview-btn2 {
    position: absolute;
    top: 5px;
    right: 50px;
    cursor: pointer;
}

.remove-btn2 {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.div-options {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.options-container {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-between;
}

/* PDF SPINNER */
.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}