.custom-switch .form-check-input {
    border: 2px solid #4354af; /* Borde verde por defecto */
    background-color: transparent; /* Fondo transparente */
}

.custom-switch .form-check-input:checked {
    background-color: #4354af; /* Color de éxito cuando esté activado */
    border-color: #4354af; /* Mantener borde verde cuando esté activado */
}

.custom-switch .form-check-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 27, 209, 0.25); /* Efecto de foco verde */
}